<template>
  <v-layout column>
    <v-row align="center" justify="center">
      <v-col xs="9" sm="9" md="6" lg="6" xl="6" class="align-center">
        <div class="align-center text-center mt-2">
          <v-card elevation="6" max-width="400" style="margin: auto">
            <v-toolbar flat dense class="blue darken-3" dark>
              <v-toolbar-title>{{ $t("titles.loginPage") }}</v-toolbar-title>
            </v-toolbar>
            <!--class that defines paddings(left, right, top && bottom-->
            <div class="pl-4 pr-4 pt-2 pb-0">
              <v-text-field
                v-on:keyup.enter="handleLogin"
                v-model="email"
                label="Email"
                filled
                dense
              ></v-text-field>
              <v-text-field
                v-on:keyup.enter="handleLogin"
                v-model="password"
                label="Password"
                :append-icon="value ? 'visibility' : 'visibility_off'"
                @click:append="() => (value = !value)"
                :type="value ? 'password' : 'text'"
                filled
                dense
              ></v-text-field>
              <v-select
                :items="serversName"
                v-model="selectedServerName"
                :label="$t('button.chooseServer')"
                filled
                dense
              >
              </v-select>
              <div class="red--text" v-html="error" />
              <br />
              <v-row>
                <v-col cols="5" class="mb-2 mt-0 pt-0">
                  <v-btn
                    style="float: left"
                    min-width="175"
                    elevation="2"
                    @click="forwardToDeviceSetup"
                    >{{ $t("button.deviceSetup") }}</v-btn
                  >
                </v-col>
                <v-col class="mb-2 mt-0 pt-0">
                  <v-btn
                    class="white--text"
                    style="float: right"
                    min-width="175"
                    color="blue darken-3"
                    elevation="2"
                    @click="handleLogin"
                    >{{ $t("button.login") }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <p>
                  <router-link to="ForgotPassword"
                    >Forgot password?</router-link
                  >
                </p>
                <v-spacer></v-spacer>
              </v-row>
            </div>
          </v-card>
        </div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
import AuthenticationService from "@/services/AuthenticationService";
import HelperMethods from "@/utilities/HelperMethods";
import ServersService from "@/services/ServersService";
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      userServices: {},
      email: "",
      password: "",
      value: String,
      serversName: [],
      selectedServerName: null,
      servers: null,
      error: null,
    };
  },

  async mounted() {
    try {
      let response = await ServersService.getServersList();

      this.servers = response.data;
      console.log("servers: ", this.servers);

      for (let i = 0; i < this.servers.length; i++) {
        this.serversName.push(this.servers[i].name);
      }
      console.log("serversName", this.serversName);
    } catch (err) {
      console.log(err);
    }
  },

  methods: {
    changeLocale(locale) {
      i18n.locale = locale;
    },
    forwardToDeviceSetup() {
      this.$router.push({
        name: "deviceSetup",
        query: { redirect: "/deviceSetup" },
      });
    },

    async handleLogin() {
      // Logic to block access to the portal from devices which have a screen size smaller than an iPad
      if (
        this.$vuetify.breakpoint.name === "xs" ||
        this.$vuetify.breakpoint.name === "sm"
      ) {
        this.error =
          "The device from which you are trying to access the HEWA portal is not yet supported.<br>Please access the app store and download the free mobile app or switch to a desktop.";
        return;
      }

      // If no server was selected block the login attempt and throw an error
      if (this.selectedServerName === null) {
        this.error =
          "Select a server before login in.";
        return;
      }

      let serverUrl = HelperMethods.getServerUrlFromServerName(
        this.servers,
        this.selectedServerName
      );

      // Store on the vuex store the, selected by the user, server
      this.$store.dispatch("setServer", serverUrl);

      try {
        const response = await AuthenticationService.login({
          email: this.email,
          password: this.password,
        });

        if (response.data.user.prefLang != null) {
          this.changeLocale(response.data.user.prefLang);
        } else this.changeLocale == "en";

        // Load all service references to an array
        let services = [];
        for (let i = 0; i < response.data.services.length; i++) {
          services.push(response.data.services[i].refService);
        }
        for (let i = 0; i < response.data.extras.length; i++) {
          services.push(response.data.extras[i].refService);
        }
        services.sort((a, b) => a - b);

        // Build an object of booleans to inform the app about which services are true for this user
        this.userServices = HelperMethods.buildServicesObject(services);

        let group = response.data.group;
        group.users = response.data.groupUsers.length;

        // Vuex
        this.$store.dispatch("setToken", response.data.token);
        this.$store.dispatch("setUser", response.data.user);
        this.$store.dispatch("setDevices", response.data.devices);
        this.$store.dispatch("setRole", response.data.role);
        this.$store.dispatch("setExtras", response.data.extras);
        this.$store.dispatch("setGroup", group);
        this.$store.dispatch("setServices", this.userServices);
        this.$store.dispatch("setBlueprints", response.data.blueprints);

        this.$router.push({ name: "lobby", query: { redirect: "/lobby" } });
      } catch (error) {
        this.error = error.response.data.error;
        console.log(error);
      }
    },
  },
};
</script>

<style scoped></style>
